import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedAuth = localStorage.getItem("isAuthenticated");

    if (storedToken && storedAuth === "true") {
      const decodedToken = jwtDecode(storedToken);
      const storedUserId = decodedToken.userId;
      const storedRole = decodedToken.role;

      setToken(storedToken);
      setRole(storedRole);
      setUserId(storedUserId);
      setIsAuthenticated(true);
    }
  }, []);

  const login = (token) => {
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.userId;
    const role = decodedToken.role;

    setToken(token);
    setRole(role);
    setUserId(userId);
    setIsAuthenticated(true);

    localStorage.setItem("token", token);
    localStorage.setItem("role", role);
    localStorage.setItem("userId", userId);
    localStorage.setItem("isAuthenticated", "true");
  };

  const logout = () => {
    setToken(null);
    setRole(null);
    setUserId(null);
    setIsAuthenticated(false);

    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    localStorage.removeItem("isAuthenticated");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, token, role, userId, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
