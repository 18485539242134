import React from "react";
import "./castingCall.css";
import {
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";

const SearchFilter = ({ searchInputs, setSearchInputs, handleSearch }) => {
  const handleInputChange = (name, value) => {
    setSearchInputs((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <div className="castingCal-filter-wrapper">
      <div className="castingCall-filter-card">
        <div className="castingCall-filter-header">
          <span className="filter-topic">Search</span>
          <button className="filter-button" onClick={handleSearch}>
            Apply
          </button>
        </div>
        <div className="castingCall-filter-body">
          <TextField
            label="Search here..."
            name="name"
            value={searchInputs.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            variant="filled"
            fullWidth
            sx={{
              backgroundColor: "transparent",
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
            }}
          />
        </div>
      </div>
      <div className="castingCall-filter-card">
        <div className="castingCall-filter-header">
          <span className="filter-topic">Location</span>
          <button className="filter-button" onClick={handleSearch}>
            Apply
          </button>
        </div>
        <div className="castingCall-filter-body">
          <FormControl
            fullWidth
            variant="filled"
            sx={{
              backgroundColor: "transparent",
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
            }}
          >
            <InputLabel>Country</InputLabel>
            <Select
              value={searchInputs.country}
              onChange={(e) => handleInputChange("country", e.target.value)}
              name="country"
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem value="worldwide">Worldwide</MenuItem>
              <MenuItem value="canada">Canada</MenuItem>
              <MenuItem value="united states">United States</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Zip code or city"
            name="zip"
            value={searchInputs.zip}
            onChange={(e) => handleInputChange("zip", e.target.value)}
            variant="filled"
            fullWidth
            sx={{
              backgroundColor: "transparent",
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
            }}
          />
          {/* <FormControl
            fullWidth
            variant="filled"
            sx={{
              backgroundColor: "transparent",
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
            }}
          >
            <InputLabel>Radius</InputLabel>
            <Select
              value={searchInputs.radius}
              onChange={(e) => handleInputChange("radius", e.target.value)}
              name="radius"
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem value="25">25 Miles</MenuItem>
              <MenuItem value="50">50 Miles</MenuItem>
              <MenuItem value="100">100 Miles</MenuItem>
              <MenuItem value="150">150 Miles</MenuItem>
              <MenuItem value="200">200 Miles</MenuItem>
            </Select>
          </FormControl> */}
        </div>
      </div>
      <div className="castingCall-filter-card">
        <div className="castingCall-filter-header">
          <span className="filter-topic">Details</span>
          <button className="filter-button" onClick={handleSearch}>
            Apply
          </button>
        </div>
        <div className="castingCall-filter-body">
          <TextField
            label="Age"
            name="age"
            value={searchInputs.age}
            onChange={(e) => handleInputChange("age", e.target.value)}
            variant="filled"
            fullWidth
            sx={{
              backgroundColor: "transparent",
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
            }}
          />
          <FormControl
            fullWidth
            variant="filled"
            sx={{
              backgroundColor: "transparent",
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
            }}
          >
            <InputLabel>Gender</InputLabel>
            <Select
              value={searchInputs.gender}
              onChange={(e) => handleInputChange("gender", e.target.value)}
              name="gender"
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="lesbian">Lesbian</MenuItem>
              <MenuItem value="transgender">Transgender</MenuItem>
              <MenuItem value="nonbinary">Non Binary</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="castingCall-filter-card">
        <div className="castingCall-filter-header">
          <span className="filter-topic">Categories</span>
          <button className="filter-button" onClick={handleSearch}>
            Apply
          </button>
        </div>
        <div className="castingCall-filter-body">
          <FormControl
            fullWidth
            variant="filled"
            sx={{
              backgroundColor: "transparent",
              "& .MuiFilledInput-root": {
                backgroundColor: "transparent",
              },
            }}
          >
            <InputLabel>Categories</InputLabel>
            <Select
              value={searchInputs.category}
              onChange={(e) => handleInputChange("category", e.target.value)}
              name="category"
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem value="acting">Acting</MenuItem>
              <MenuItem value="modeling">Modeling</MenuItem>
              <MenuItem value="pageant">Pageant</MenuItem>
              <MenuItem value="tvShows">Tv Shows</MenuItem>
              <MenuItem value="theater">Theater</MenuItem>
              <MenuItem value="voice&Movies">Voice over and Movies</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
