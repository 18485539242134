import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Actors from "./pages/actors/Actors";
import ActorProfile from "./pages/actors/ActorProfile";
import RegistrationForm from "./pages/registration/index";
import CastingCalls from "./pages/castingCalls/CastingCalls";
import Review from "./pages/review/Review";
import CastingDirector from "./pages/castingDirector/CastingDirector";
import CastingCallViewPage from "./pages/castingCalls/CastingCallView";
import ActorProfilePage from "./pages/profile/ActorProfilePage";
import DirectorProfilePage from "./pages/profile/CastingDirector/DirectorProfilePage";
import SettingPage from "./pages/setting/SettingPage";
import SubscriptionPlan from "./pages/subscription/SubscriptionPlan";
import ActorCrew from "./pages/actorCrew/ActorCrew";
import CastingCallCreatePage from "./pages/castingCalls/CastingCallCreate";
import AboutUsPage from "./pages/about/About";
import CastingCallEditPage from "./pages/castingCalls/CasingCallEdit";
import PrivateRoutes from "./utils/PrivateRoutes";
import HomePrivateRoutes from "./utils/HomePrivateRoute";
import DirectorPrivateRoutes from "./utils/DirectorPrivateRoute";
import ProducerPrivateRoutes from "./utils/ProducerPrivateRoute";

function App() {
  return (
    <div className="app">
      <Routes>
        <Route element={<HomePrivateRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/castingdirectors" element={<CastingDirector />} />
        </Route>

        <Route path="/castingcalls" element={<CastingCalls />} />
        <Route
          path="/castingcalls/view/:id"
          element={<CastingCallViewPage />}
        />
        <Route path="/actors" element={<Actors />} />
        <Route path="/reviews" element={<Review />} />
        <Route path="/actors/:name/:id" element={<ActorProfile />} />
        <Route path="/aboutUs" element={<AboutUsPage />} />

        <Route element={<ProducerPrivateRoutes />}>
          <Route path="/crew" element={<ActorCrew />} />
        </Route>
        
        <Route element={<PrivateRoutes />}>
          <Route path="/profile" element={<ActorProfilePage />} />
          <Route path="/setting" element={<SettingPage />} />
          <Route path="/profile-director" element={<DirectorProfilePage />} />

          <Route element={<DirectorPrivateRoutes />}>
            <Route
              path="/castingcalls/create"
              element={<CastingCallCreatePage />}
            />
            <Route
              path="/castingcalls/edit/:id"
              element={<CastingCallEditPage />}
            />
          </Route>
          <Route path="/subscriptions" element={<SubscriptionPlan />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
