import React, { useEffect, useState } from 'react';
import PlanOption from './PlanOption';
import './subscriptionPlans.css';
import { getSubscriptionPlans } from "../../api/axios";
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios';
const SubscriptionPlans = () => {
  const token = localStorage.getItem("token");
  const [selectedPlan, setSelectedPlan] = useState(0);

  const [plansData, setPlansData] = useState([
    // {
    //   id: 1,
    //   duration: "12 Months",
    //   originalPrice: 35.99,
    //   price: 99,
    //   savingsPercentage: 86,
    //   hasGuarantee: true,
    //   isBestDeal: true,
    // },
    // {
    //   id: 3,
    //   duration: "A Casting Call",
    //   originalPrice: 35.99,
    //   price: 9.99,
    //   savingsPercentage: 33,
    //   hasGuarantee: false,
    //   isBestDeal: false,
    // },
  ]);
  const getSubscriptionPlansHandler = async () => {
  try {
    const result= await getSubscriptionPlans()
    console.log("🚀 ~ getSubscriptionPlansHandler ~ result:", result.result);
    if (result.status) {
      setPlansData(result.result);
      if (result.result.length > 0) {
        setSelectedPlan(result.result[0].id);
      } 
    }
  } catch (error) {
    console.log("error:", error)
  }
}

  useEffect(() => {
    getSubscriptionPlansHandler()
  },[])
  // const plansData = [
  //   {
  //     id: 1,
  //     duration: "12 Months",
  //     // originalPrice: 35.99,
  //     price: 99,
  //     // savingsPercentage: 86,
  //     // isBestDeal: true
  //   },
  //   {
  //     id: 3,
  //     duration: "A Casting Call",
  //     // originalPrice: 35.99,
  //     price: 9.99,
  //     // savingsPercentage: 33,
  //     // isBestDeal: false
  //   }
  // ];


  const handlePlanSelect = (planId) => {
    setSelectedPlan(planId);
  };

  const handleSubscribe = async () => {
    try {
      console.log(`Subscribing to plan: ${selectedPlan}`);
          const stripe = await loadStripe(
            "pk_test_51QA1rnJH7ExR9sZzq4rKnB16K6ShIPNVk7TRaNKM0RHiV9VuWO7awrCikSKBl7fpsS20wLVOdTNJCek4op4MFjrk00ruWmpa1R"
          );
          console.log("🚀 ~ handleSubscribe ~ token:", token)
            const response = await axios.post(
              "https://admin.kid.hasthiya.org/payments/crete-checkout-subscription",
              { packageId: selectedPlan },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
      
        if (response.data.status) {
          stripe.redirectToCheckout({
            sessionId: response.data.id,
          });
        } else {

        }
      
    } catch (error) {
      console.log("🚀 error:", error)
    }
  };

  return (
    <div className='subscriptions-container' >
        <div className='subscription-header'>
            <h1 className='subscription-title'>Select a Plan To Subscribe</h1>
            <p className='subscription-subtitle'>Get Discovered by Top Industry Professionals</p>
        </div>
        <div className="subscription-container">
           <div className="plans">
        {plansData.map((plan) => (
          <PlanOption
            key={plan.id}
            plan={plan}
            isSelected={selectedPlan === plan.id}
            onSelect={() => handlePlanSelect(plan.id)}
          />
        ))}
      </div>
      <button className="subscribe-btn" onClick={handleSubscribe}>
        Subscribe Now
      </button> 
        </div>

      
    </div>
  );
};

export default SubscriptionPlans;