import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./modal.css";
import Modal from "@mui/material/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faStar } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/AuthContext";

const ActorModal = ({ actor, closeModal, contactActor, isModalOpen }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  const handleViewProfile = (name, id) => {
    navigate(`/actors/${name}/${id}`);
  };

  const handleContactActor = () => {
    contactActor();
  };

  return (
    <Modal open={isModalOpen} onClose={closeModal}>
      <div className="actor-modal-wrapper">
        <div className="actor-modal-left">
          <div className="gardient-left"></div>
          <div className="gardient-right"></div>
          <img
            src={actor.profile.imageURL}
            alt=" "
            className="modal-profile-pic"
          />
        </div>
        <div className="actor-modal-details">
          <div className="actor-modal-close" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} />{" "}
          </div>
          <div className="actor-modal-name">
            {actor.user.firstName} {actor.user.lastName}
          </div>
          <div className="actor-modal-rating">
            <div className="modal-rating">
              <FontAwesomeIcon icon={faStar} className="actor-modal-star" />
              <span>8575</span>
            </div>
            <span>PROFILE ID: {actor.user.id}</span>
          </div>
          <div className="seperate-line"></div>
          <div className="actor-modal-user-details">
            <div className="actorModal-userDetal-section">
              <span className="actor-modal-detail-key">Age :</span>
              <span>{actor.user.age} y.o.</span>
            </div>
            <div className="actorModal-userDetal-section">
              <span className="actor-modal-detail-key">Appearance :</span>
              <span className="actor-modal-appearance">
                <span>{actor.user.gender}</span>
                {actor.profile.height && (
                  <span>
                    {actor.profile.height}{" "}
                    <span className="appearance-text">height</span>
                  </span>
                )}
                {actor.profile.weight && (
                  <span>
                    {actor.profile.weight}{" "}
                    <span className="appearance-text">weight</span>
                  </span>
                )}
                {actor.profile.hairColor && (
                  <span>
                    {actor.profile.hairColor}{" "}
                    <span className="appearance-text">hair</span>
                  </span>
                )}
                {actor.profile.eyeColor && (
                  <span>
                    {actor.profile.eyeColor}{" "}
                    <span className="appearance-text">eyes</span>
                  </span>
                )}
              </span>
            </div>
            {actor.profile.about && (
              <div className="actorModal-userDetal-section">
                <span className="actor-modal-detail-key">About :</span>
                <span>{actor.profile.about}</span>
              </div>
            )}
            {actor.profile.hobbies && (
              <div className="actorModal-userDetal-section">
                <span className="actor-modal-detail-key">Hobbies :</span>
                <span>{actor.profile.hobbies}</span>
              </div>
            )}
          </div>
          <div className="seperate-line"></div>
          <div className="actor-modal-footer">
            {!isAuthenticated && (
              <button
                className="actor-contact-button "
                onClick={handleContactActor}
              >
                Contact Actor
              </button>
            )}
            <button
              className="view-profile-button"
              onClick={() => handleViewProfile(actor.user.firstName,actor.user.id)}
            >
              View Full Profile
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ActorModal;
