import React, { useState } from "react";
import "./setting.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Settings = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleOldPassword = () => setShowOldPassword((prev) => !prev);
  const toggleNewPassword = () => setShowNewPassword((prev) => !prev);
  const toggleConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

  return (
    <div className="settings">
      <div className="settings-main">
        <div className="settings-sidebar">
          <div className="seachtopic">Settings</div>
          <div className="suptopic">Account Settings</div>
          <div className="settings-options">
            <div className="settings-option active">
              <img
                src="https://talent.kidscasting.com/assets/icons/icon-lock-filled.svg"
                alt="Password"
              />
              Password
            </div>
            <div className="suptopic">Billing Information</div>
            <div className="settings-option">
              <img
                src="https://talent.kidscasting.com/assets/icons/icon-subscription-filled.svg"
                alt="Subscription"
              />
              Subscription
            </div>
            <div className="suptopic">Notifications</div>
            <div className="settings-option">
              <img
                src="https://talent.kidscasting.com/assets/icons/icon-email-filled.svg"
                alt="Email Notifications"
              />
              Email Notifications
            </div>
            <div className="suptopic">Account Settings</div>
            <div className="settings-option">
              <img
                src="https://talent.kidscasting.com/assets/icons/icon-email-filled.svg"
                alt="Deactivate Account"
              />
              Deactivate Account
            </div>
          </div>
        </div>
        <div className="settings-content">
          <h2>Password</h2>
          <form className="password-form">
            <div className="form-group">
              <label>Old Password</label>
              <div className="password-input-wrapper">
                <input
                  type={showOldPassword ? "text" : "password"}
                  placeholder="Enter old password"
                />
                <span className="toggle-password" onClick={toggleOldPassword}>
                  <FontAwesomeIcon
                    icon={showOldPassword ? faEyeSlash : faEye}
                  />
                </span>
              </div>
            </div>
            <div className="form-group">
              <label>New Password</label>
              <div className="password-input-wrapper">
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Enter new password"
                />
                <span className="toggle-password" onClick={toggleNewPassword}>
                  <FontAwesomeIcon
                    icon={showNewPassword ? faEyeSlash : faEye}
                  />
                </span>
              </div>
              <small>Min 8 characters</small>
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <div className="password-input-wrapper">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm new password"
                />
                <span
                  className="toggle-password"
                  onClick={toggleConfirmPassword}
                >
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEyeSlash : faEye}
                  />
                </span>
              </div>
              <small>Min 8 characters</small>
            </div>
            <button type="submit">Save changes</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
