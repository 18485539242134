import React, { useEffect, useState, useRef, useContext } from "react";
import "./actorDetails.css";
import { useParams, useNavigate } from "react-router-dom";
import ProfileImage from "../../assets/actor1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import YoutubeCard from "./YoutubeCard";
import { getActorsById } from "../../api/axios";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import { Spinner } from "react-bootstrap";

const ActorDetails = () => {
  const { id } = useParams();
  const nameRef = useRef(null);
  const imageRef = useRef(null);
  const interestRef = useRef(null);
  const ageRef = useRef(null);
  const ethnicityRef = useRef(null);
  const appearanceRef = useRef(null);
  const socialRef = useRef(null);
  const talentsRef = useRef(null);
  const aboutRef = useRef(null);
  const hobbiesRef = useRef(null);
  const videoRef = useRef(null);

  const [actor, setActor] = useState({});
  const [loading, setLoading] = useState(true);
  const [visibleVideos, setVisibleVideos] = useState(4);

  const GetActorById = async (id) => {
    nprogress.start();
    try {
      const res = await getActorsById(id);
      if (res.success) {
        setActor(res.user);
      }
    } catch (error) {
      console.log("error fetching actor", error);
    } finally {
      nprogress.done();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      GetActorById(id);
    }
  }, [id]);

  const handleLoadMore = () => {
    setVisibleVideos((prevCount) => prevCount + 4);
  };

  const videoLinks = [
    "https://www.youtube.com/watch?v=Gw1Amw6Duis",
    "https://www.youtube.com/watch?v=CgkZ7MvWUAA",
    "https://www.youtube.com/watch?v=m55PTVUrlnA",
    "https://www.youtube.com/watch?v=Gw1Amw6Duis",
    "https://www.youtube.com/watch?v=CgkZ7MvWUAA",
    "https://www.youtube.com/watch?v=m55PTVUrlnA",
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const scrollToRef = (ref) => {
    if (ref) {
      const navbarHeight = 105;
      const topPosition =
        ref.getBoundingClientRect().top + window.scrollY - navbarHeight;
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  if (loading) {
    return (
      <div className="spinner-page">
        <Spinner className="spinner" />
      </div>
    );
  }
  return (
    <div className="actor-fullProfile-wrapper">
      <div className="actor-fullProfile-container">
        <div className="actor-fullProfile-grid">
          <div className="actor-fullProfile-top">
            <div className="actor-fullProfile-details">
              <div className="actor-fullProfile-image" ref={imageRef}>
                <img
                  src={actor.result.imageURL}
                  alt=" "
                  className="actor-fullProfile-photo"
                />
              </div>
              <div className="actor-fullProfile-details-right">
                <div className="actor-fullProfile-name" ref={nameRef}>
                  {actor.registerResult.firstName}{" "}
                  {actor.registerResult.lastName}
                </div>
                <div className="actor-modal-rating">
                  <div className="modal-rating">
                    <FontAwesomeIcon
                      icon={faStar}
                      className="actor-modal-star"
                    />
                    <span>8575</span>
                  </div>
                  <span>PROFILE ID: {actor.registerResult.id}</span>
                </div>
                <div className="seperate-line"></div>
                {actor.result.interestedIn && (
                  <div className="actorModal-userDetal-section">
                    <span className="actor-modal-detail-key" ref={interestRef}>
                      Interested in :
                    </span>
                    <span>{actor.result.interestedIn}</span>
                  </div>
                )}
                <div className="actorModal-userDetal-section">
                  <span className="actor-modal-detail-key" ref={ageRef}>
                    Age :
                  </span>
                  <span>{actor.result.age} y.o.</span>
                </div>
                <div className="actorModal-userDetal-section">
                  <span className="actor-modal-detail-key" ref={ethnicityRef}>
                    Ethnicity :
                  </span>
                  <span>{actor.registerResult.ethnicity}</span>
                </div>
                <div className="actorModal-userDetal-section">
                  <span className="actor-modal-detail-key" ref={appearanceRef}>
                    Appearance :
                  </span>
                  <span>{actor.registerResult.gender}</span>
                </div>
                <div className="actorModal-userDetal-section">
                  <span className="actor-modal-detail-key" ref={socialRef}>
                    Social Profiles :
                  </span>
                  <span>Media logos</span>
                </div>
                {actor.result.talents && (
                  <div className="actorModal-userDetal-section">
                    <span className="actor-modal-detail-key" ref={talentsRef}>
                      Talents :
                    </span>
                    <span>{actor.result.talents}</span>
                  </div>
                )}
                {actor.result.about && (
                  <div className="actorModal-userDetal-section">
                    <span className="actor-modal-detail-key" ref={aboutRef}>
                      About :
                    </span>
                    <span>{actor.result.about}</span>
                  </div>
                )}
                {actor.result.hobbies && (
                  <div className="actorModal-userDetal-section">
                    <span className="actor-modal-detail-key" ref={hobbiesRef}>
                      Hobbies :
                    </span>
                    <span>{actor.result.hobbies}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="seperate-line"></div>
            <div className="actor-fullProfile-name" ref={videoRef}>
              Demo Reel
            </div>
            <div className="actor-fullProfile-video-container">
              {videoLinks?.slice(0, visibleVideos).map((videoLink, index) => (
                <YoutubeCard key={index} videoLink={videoLink} />
              ))}
            </div>
            <div className="yt-loadmore">
              {visibleVideos < videoLinks.length && (
                <button onClick={handleLoadMore} className="homeMiddle2">
                  Load More
                </button>
              )}
            </div>
          </div>
          <div className="actor-fullProfile-ref-container">
            <span className="actor-fullProfile-ref-title">Overview</span>
            <div className="actor-fullProfile-ref-list">
              <span
                className="actor-fullProfile-ref"
                onClick={() => scrollToRef(nameRef.current)}
              >
                Name
              </span>
              <span
                className="actor-fullProfile-ref"
                onClick={() => scrollToRef(interestRef.current)}
              >
                Interested in
              </span>
              <span
                className="actor-fullProfile-ref"
                onClick={() => scrollToRef(ageRef.current)}
              >
                Age
              </span>
              <span
                className="actor-fullProfile-ref"
                onClick={() => scrollToRef(ethnicityRef.current)}
              >
                Ethnicity
              </span>
              <span
                className="actor-fullProfile-ref"
                onClick={() => scrollToRef(appearanceRef.current)}
              >
                Appearance
              </span>
              <span
                className="actor-fullProfile-ref"
                onClick={() => scrollToRef(socialRef.current)}
              >
                Social Profiles
              </span>
              <span
                className="actor-fullProfile-ref"
                onClick={() => scrollToRef(talentsRef.current)}
              >
                Talents
              </span>
              <span
                className="actor-fullProfile-ref"
                onClick={() => scrollToRef(aboutRef.current)}
              >
                About
              </span>
              <span
                className="actor-fullProfile-ref"
                onClick={() => scrollToRef(hobbiesRef.current)}
              >
                Hobbies
              </span>
              <span
                className="actor-fullProfile-ref"
                onClick={() => scrollToRef(imageRef.current)}
              >
                Main Photo
              </span>
              <span
                className="actor-fullProfile-ref"
                onClick={() => scrollToRef(videoRef.current)}
              >
                Demo Reel
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActorDetails;
