import React, { useState } from "react";
import "./actorDetails.css";

const YoutubeCard = ({ videoLink, onClick }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const videoId = videoLink.split("v=")[1];

  const handleVideoClick = () => {
    setIsPlaying(true);
  };

  return (
    <div className="yt-card">
      {isPlaying ? (
        <iframe
          className="yt-playing"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title="YouTube video player"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      ) : (
        <img
          src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
          alt="Video Thumbnail"
          className="yt-playing"
          onClick={handleVideoClick}
        />
      )}
    </div>
  );
};

export default YoutubeCard;
