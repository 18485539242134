import React from "react";
import ActorDetails from "../../components/actorProfile/ActorDetails";
import Layout from "../../components/layout/Layout";

const ActorProfile = () => {
  return (
    <Layout>
      <div>
        <ActorDetails />
      </div>
    </Layout>
  );
};

export default ActorProfile;
