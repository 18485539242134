import React, { useState, useEffect, useContext } from "react";
import {
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ActorForm from "./ActorForm";
import DirectorForm from "./DirectorForm";
import CrewMemberForm from "./CrewMemberForm";
import { SignUp } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/common/Alert";
import {
  validateField,
  validateForm,
  checkAllRequiredFieldsFilled,
} from "./validation";
import PrivacyPolicyPopup from "../../components/agreement/privacyPolicy/PrivacyPolicy";
import TermOfUsePopup from "../../components/agreement/termOfUse/TermOfUse";
import LoginPopup from "../../components/auth/LoginPopup";
import actorImage from "../../assets/actorImage.jpg";
import directorImage from "../../assets/directorImage.jpg";
import crewMemberImage from "../../assets/crewMemberImage.jpg";
import "./registraton.css";
import { AuthContext } from "../../context/AuthContext";

const Registration = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    zipCode: "",
    email: "",
    phoneNumber: "",
    gender: "",
    birthday: "",
    ethnicity: "",
    age: "",
    password: "",
    confirmPassword: "",
    selectedRole: "",
    agreement: "",
  });
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [termOfUseOpen, setTermOfUseOpen] = useState(false);
  const [touchedFields, setTouchedFields] = useState({
    firstName: false,
    lastName: false,
    zipCode: false,
    email: false,
    phoneNumber: false,
    gender: false,
    birthday: false,
    ethnicity: false,
    age: false,
    password: false,
    confirmPassword: false,
    selectedRole: false,
    agreement: false,
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setFormData({
      firstName: "",
      lastName: "",
      zipCode: "",
      email: "",
      phoneNumber: "",
      gender: "",
      birthday: "",
      ethnicity: "",
      age: "",
      password: "",
      confirmPassword: "",
      selectedRole: "",
      agreement: false,
    });
    setErrors({});
    setTouchedFields({});
    setIsFormValid(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: fieldValue,
    }));

    if (name === "birthday") {
      const age = calculateAge(value);
      setFormData((prev) => ({ ...prev, age }));
    }

    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [name]: true,
    }));

    const fieldErrors = validateField(
      name,
      fieldValue,
      { ...formData, [name]: fieldValue },
      tabValue,
      touchedFields
    );

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors,
    }));

    const newFormData = { ...formData, [name]: fieldValue };
    const formErrors = validateForm(newFormData, tabValue);
    const allFieldsFilled = checkAllRequiredFieldsFilled(newFormData);
    const hasErrors = Object.keys(formErrors).length > 0;

    setIsFormValid(allFieldsFilled && !hasErrors);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const newErrors = validateField(
      name,
      value,
      formData,
      tabValue,
      touchedFields
    );
    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm(formData, tabValue, touchedFields);
    setErrors(formErrors);

    const allFieldsFilled = checkAllRequiredFieldsFilled(formData);
    const hasErrors = Object.keys(formErrors).length > 0;

    if (!allFieldsFilled || hasErrors) {
      setIsFormValid(false);
      return;
    }

    setIsFormValid(true);

    const body = {
      email: formData.email,
      password: formData.password,
      firstName: formData.firstName,
      lastName: formData.lastName,
      zipCode: formData.zipCode,
      gender: formData.gender,
      contactNumber: formData.phoneNumber,
      birthday: formData.birthday,
      ethnicity: formData.ethnicity,
      role:
        tabValue === 0
          ? "actor"
          : tabValue === 1
          ? formData.selectedRole === "director"
            ? "director"
            : "producer"
          : "crew_member",
    };

    try {
      const res = await SignUp(body);

      if (res.success) {
        console.log("Form data submitted:", formData);
        login(res.token);

        setOpenSnackbar(true);
        setAlertSeverity("success");
        setAlertMessage("Registration successful!");

        setFormData({
          firstName: "",
          lastName: "",
          zipCode: "",
          email: "",
          phoneNumber: "",
          gender: "",
          birthday: "",
          ethnicity: "",
          age: "",
          password: "",
          confirmPassword: "",
          selectedRole: "",
          agreement: false,
        });
        setErrors({});
        setTouchedFields({});
        setIsFormValid(false);

        setTimeout(() => {
          navigate("/profile");
        }, 2000);
      } else throw new Error(res.message);
    } catch (error) {
      console.error("Registration error:", error);

      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Something went wrong";
      setAlertMessage(errorMessage);
      setAlertSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const getBulletPoints = () => {
    switch (tabValue) {
      case 0:
        return (
          <ul className="list">
            <li className="list-item">
              Apply to roles matching your unique talents
            </li>
            <li className="list-item">
              Create a professional profile to showcase your skills
            </li>
          </ul>
        );
      case 1:
        return (
          <ul className="list">
            <li className="list-item">Free to cast a project</li>
            <li className="list-item">Easy to find the right talent</li>
            <li className="list-item">
              Simple to navigate the virtual casting office
            </li>
            <li className="list-item">
              Quick to reach 2+ Million diverse talent
            </li>
          </ul>
        );
      case 2:
        return (
          <ul className="list">
            <li className="list-item">Network with industry professionals</li>
            <li className="list-item">Showcase your skills and experience</li>
            <li className="list-item">
              Get notified about relevant job openings
            </li>
          </ul>
        );
      default:
        return null;
    }
  };

  const getBackgroundImage = (tabValue) => {
    switch (tabValue) {
      case 0:
        return `url(${actorImage})`;
      case 1:
        return `url(${directorImage})`;
      case 2:
        return `url(${crewMemberImage})`;
      default:
        return null;
    }
  };

  const openPrivacyPolicy = (e) => {
    e.preventDefault();
    setPrivacyPolicyOpen(true);
  };

  const openTermOfUse = (e) => {
    e.preventDefault();
    setTermOfUseOpen(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Box className="registration-container">
      <Box
        className="left-panel"
        sx={{
          backgroundImage: getBackgroundImage(tabValue),
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",

          "& .MuiBox-root": {
            borderRadius: "15px 0 0 15px",
          },
        }}
      >
        <Typography
          variant="h3"
          className="page-title"
          style={{ fontSize: "2.5rem", fontWeight: 600 }}
        >
          Sign Up With Open Curtains Casting
        </Typography>
        {getBulletPoints()}
        <Typography>
          Already a member?
          <span className="login-link" onClick={() => setIsLoginOpen(true)}>
            {"  "}
            Log in
          </span>
        </Typography>
      </Box>
      <Box className="right-panel">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          className="tab-container"
          sx={{
            "& .MuiTab-root": {
              fontSize: {
                xs: "0.7rem",
                sm: "0.7rem",
                md: "0.8rem",
                lg: "0.9rem",
              },
              minWidth: {
                xs: "60px",
                sm: "80px",
                md: "100px",
              },
              padding: {
                xs: "6px 8px",
                sm: "6px 12px",
              },
              color: "#6E0506",
              "&:hover": {
                color: "#E54447",
              },
            },
            "& .Mui-selected": {
              color: "#E54447",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#E54447",
            },
          }}
        >
          <Tab label="ACTOR" />
          <Tab label="DIRECTOR" />
          <Tab label="CREW MEMBER" />
        </Tabs>
        <form onSubmit={handleSubmit}>
          {tabValue === 0 && (
            <ActorForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleBlur={handleBlur}
              errors={errors}
            />
          )}

          {tabValue === 1 && (
            <DirectorForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleBlur={handleBlur}
              errors={errors}
            />
          )}
          {tabValue === 2 && (
            <CrewMemberForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleBlur={handleBlur}
              errors={errors}
            />
          )}
          <FormControlLabel
            className="terms-checkbox"
            control={
              <Checkbox
                name="agreement"
                checked={formData.agreement}
                onChange={handleInputChange}
              />
            }
            label={
              <Typography className="checkbox-label">
                By choosing to join, I certify I am at least 18 years old and
                have read and agree to the KidsCasting.com{" "}
                <span
                  className="highlight"
                  onClick={openPrivacyPolicy}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  privacy policy
                </span>{" "}
                and{" "}
                <span
                  className="highlight"
                  onClick={openTermOfUse}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  terms of use
                </span>
                . I agree to receive welcome email, newsletter, SMS & occasional
                account updates from KidsCasting.com
              </Typography>
            }
            sx={{
              marginLeft: "0px",
              marginRight: "0px",
              borderRadius: "5px",
              alignItems: "flex-start",
              "& .MuiTypography-root": {
                fontSize: "0.875rem",
                color: "#333",
              },
              "& .MuiCheckbox-root": {
                color: "#f48fb1",
              },
            }}
          />
          <PrivacyPolicyPopup
            open={privacyPolicyOpen}
            onClose={() => setPrivacyPolicyOpen(false)}
          />

          <TermOfUsePopup
            open={termOfUseOpen}
            onClose={() => setTermOfUseOpen(false)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#6E0506 !important",
              "&:hover": {
                backgroundColor: "#6E0506 !important",
              },
              "&.Mui-disabled": {
                backgroundColor: "#E54447 !important",
                color: "#fff",
              },
              color: "#fff",
            }}
            disabled={!isFormValid}
          >
            Sign Up
          </Button>
        </form>
      </Box>

      <LoginPopup isLoginOpen={isLoginOpen} setIsLoginOpen={setIsLoginOpen} />
      <Alert
        openSnackbar={openSnackbar}
        alertSeverity={alertSeverity}
        setOpenSnackbar={setOpenSnackbar}
        alertMessage={alertMessage}
      />
    </Box>
  );
};

export default Registration;
